// @mui
import { Box, BoxProps } from '@mui/material';
import { LocalBusinessJsonLd, NextSeo } from 'next-seo';
import dynamic from 'next/dynamic';
import { forwardRef, ReactNode } from 'react';
import { SEOProps } from 'src/@types/seo';

const DynamicScrollTop = dynamic(() => import('./ScrollTop'));

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  children: ReactNode;
  seo: SEOProps;
  canonical?: string;
}

const Page = forwardRef<HTMLDivElement, Props>(({ children, seo, canonical, ...other }, ref) =>
  seo ? (
    <>
      <NextSeo
        title={seo?.metaTitle}
        description={seo?.metaDesc}
        openGraph={{
          title: seo?.shareTitle,
          description: seo?.shareDesc,
        }}
        {...(canonical && { canonical: canonical })}
      />
      <LocalBusinessJsonLd
        type="LocalBusiness"
        id="https://b-e-a.no/"
        name="Bergen Elektro Automasjon"
        description="BEA har siden oppstarten i 2000 utviklet seg til å bli en ledende elektriker i Bergen og omegn. Trenger du hjelp - spør oss."
        url="https://b-e-a.no/"
        telephone="+4755913319"
        address={{
          streetAddress: 'Nedre Nøttveit 62',
          addressLocality: 'Rådal',
          addressRegion: 'Hordaland',
          postalCode: '5238',
          addressCountry: 'NO',
        }}
        openingHours={[
          {
            opens: '08:00',
            closes: '16:00',
            dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
            validFrom: '2019-12-23',
            validThrough: '2026-04-02',
          },
        ]}
      />
      <div id="back-to-top-anchor" />
      <Box ref={ref} {...other}>
        {children}
      </Box>
      <DynamicScrollTop />
    </>
  ) : (
    <>
      <div id="back-to-top-anchor" />
      <Box ref={ref} {...other}>
        {children}
      </Box>
      <DynamicScrollTop />
    </>
  )
);

export default Page;
