// @mui
import { Box, Dialog, DialogProps, Paper } from '@mui/material';

// ----------------------------------------------------------------------

export interface Props extends DialogProps {
  onClose?: VoidFunction;
}

export default function DialogAnimate({
  open = false,
  onClose,
  children,
  sx,
  ...other
}: Props) {
  return (
    <>
      {open && (
        <Dialog
          fullWidth
          maxWidth="xs"
          open={open}
          onClose={onClose}
          PaperComponent={(props) => (
            <Box
              sx={{
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Box onClick={onClose} sx={{ width: '100%', height: '100%', position: 'fixed' }} />
              <Paper sx={sx} {...props}>
                {props.children}
              </Paper>
            </Box>
          )}
          {...other}
        >
          {children}
        </Dialog>
      )}
    </>
  );
}
