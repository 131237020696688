const SEO = {
  title: undefined,
  titleTemplate: '%s - Bergen Elektro Automasjon',
  defaultTitle: 'Bergen Elektro Automasjon',
  description: 'Elektriker Bergen',
  openGraph: {
    type: 'website',
    locale: 'nb_NO',
    url: '',
    site_name: 'Bergen Elektro Automasjon',
  },
  twitter: {
    handle: '@handle',
    site: '@site',
    cardType: 'summary_large_image',
  },
  additionalLinkTags: [
    {
      rel: 'icon',
      href: '/favicon.png',
      type: 'image/png',
    },
  ],
};

export default SEO;
