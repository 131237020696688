import { Box, GlobalStyles, IconButton, Typography } from '@mui/material';
// @mui
import { alpha, useTheme } from '@mui/material';
import { useEffect } from 'react';
import Iconify from './Iconify';
import closeIcon from '@iconify/icons-carbon/close';
import arrowLeftIcon from '@iconify/icons-carbon/arrow-left';
import arrowRightIcon from '@iconify/icons-carbon/arrow-right';
import dynamic from 'next/dynamic';

const Lightbox = dynamic(() => import('react-spring-lightbox'), {
  ssr: false,
});

// ----------------------------------------------------------------------

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

interface Props
  extends PartialBy<React.ComponentProps<typeof Lightbox>, 'onNext' | 'onPrev' | 'currentIndex'> {
  photoIndex: number;
  setPhotoIndex: (index: number) => void;
  isOpen: boolean;
}

export default function LightboxModal({
  images,
  photoIndex,
  setPhotoIndex,
  isOpen,
  ...other
}: Props) {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  const showIndex = (
    <Typography variant="subtitle2">{`${photoIndex + 1} / ${images.length}`}</Typography>
  );

  const onPrev = () => setPhotoIndex((photoIndex + images.length - 1) % images.length);
  const onNext = () => setPhotoIndex((photoIndex + 1) % images.length);

  return (
    <>
      {isOpen && (
        <Lightbox
          isOpen={isOpen}
          images={images}
          {...other}
          currentIndex={photoIndex}
          renderPrevButton={() => (
            <Box position="absolute" zIndex={1000} left={16}>
              <IconButton onClick={onPrev} sx={{ color: 'common.white', bgcolor: 'grey.800' }}>
                <Iconify fontSize={30} icon={arrowLeftIcon} />
              </IconButton>
            </Box>
          )}
          renderNextButton={() => (
            <Box position="absolute" zIndex={1000} right={16}>
              <IconButton onClick={onNext} sx={{ color: 'common.white', bgcolor: 'grey.800' }}>
                <Iconify fontSize={30} icon={arrowRightIcon} />
              </IconButton>
            </Box>
          )}
          renderHeader={() => (
            <Box position="absolute" zIndex={1000} right={16} top={16}>
              <IconButton
                onClick={other.onClose}
                sx={{ color: 'common.white', bgcolor: 'grey.800' }}
              >
                <Iconify fontSize={30} icon={closeIcon} />
              </IconButton>
            </Box>
          )}
          renderFooter={() => (
            <Box
              sx={{
                position: 'absolute',
                zIndex: 1000,
                color: 'common.white',
                width: 1,
                bottom: 24,
                textAlign: 'center',
              }}
            >
              {showIndex}
            </Box>
          )}
          onPrev={() => setPhotoIndex((photoIndex + images.length - 1) % images.length)}
          onNext={() => setPhotoIndex((photoIndex + 1) % images.length)}
          style={{ zIndex: 100000, background: alpha('#000', 0.8) }}
        />
      )}
    </>
  );
}
