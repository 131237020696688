// @mui
import { styled } from '@mui/material';
import dynamic from 'next/dynamic';
import { BaseReactPlayerProps } from 'react-player/base';

const ReactPlayer = dynamic(() => import('react-player/vimeo'), {
  ssr: false,
});

// ----------------------------------------------------------------------

export const ReactPlayerStyle = styled(ReactPlayer)(() => ({
  width: '100% !important',
  height: '100% !important',
  '& video': {
    objectFit: 'cover',
  },
}));

// ----------------------------------------------------------------------

// https://github.com/CookPete/react-player

export default function Player({ ...other }: BaseReactPlayerProps) {
  return <ReactPlayerStyle {...other} />;
}
