import { AppCacheProvider } from '@mui/material-nextjs/v13-pagesRouter';
import { NextPage } from 'next';
import { DefaultSeo } from 'next-seo';
import SEO from 'next-seo.config';
import { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { ReactElement, ReactNode, useEffect } from 'react';
import { InstantSearchSSRProvider } from 'react-instantsearch';
import MotionLazyContainer from '../src/components/animate/MotionLazyContainer';
import ProgressBar from '../src/components/ProgressBar';
import ThemeProvider from '../src/theme';
import * as gtag from '../utils/gtag';

// ----------------------------------------------------------------------

export type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

interface MyAppProps extends AppProps {
  Component: NextPageWithLayout;
}

export default function MyApp(props: MyAppProps) {
  const { Component, pageProps } = props;
  const router = useRouter();

  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      gtag.pageView(url);
    };
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  });

  return (
    <>
      <InstantSearchSSRProvider {...pageProps.serverState}>
        <AppCacheProvider {...props}>
          <Head>
            <meta name="viewport" content="initial-scale=1, width=device-width" />
          </Head>
          <DefaultSeo {...SEO} />

          <ThemeProvider>
            <MotionLazyContainer>
              <ProgressBar />
              {getLayout(<Component {...pageProps} />)}
            </MotionLazyContainer>
          </ThemeProvider>
        </AppCacheProvider>
      </InstantSearchSSRProvider>
    </>
  );
}

// // Web Vitals
// export function reportWebVitals(metric: { label: string; }) {
//   console.log(metric)
// }
